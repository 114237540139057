var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LegalModal" },
    [
      _vm.legalVisible
        ? _c(
            "Modal",
            {
              attrs: {
                "z-index": 8,
                closable: true,
                visible: _vm.legalVisible,
                title: (_vm.formType === "add" ? "新增" : "设置") + "法定节假",
                size: "normal",
                "overflow-auto": "",
              },
              on: {
                ok: _vm.addLegal,
                cancel: function ($event) {
                  _vm.legalVisible = false
                },
              },
            },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "法定节假名称", prop: "holidayName" } },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入法定节假名称",
                          disabled:
                            _vm.formType === "edit" &&
                            !_vm.form.userDefinedFlag,
                        },
                        model: {
                          value: _vm.form.holidayName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "holidayName", $$v)
                          },
                          expression: "form.holidayName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "放假时间", prop: "holidayTime" } },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          "value-format": _vm.format,
                          "allow-clear": false,
                          "disabled-date": _vm.disabledDate,
                        },
                        on: { change: _vm.holidayTimeChange },
                        model: {
                          value: _vm.form.holidayTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "holidayTime", $$v)
                          },
                          expression: "form.holidayTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.holidayDays
                    ? _c(
                        "a-form-model-item",
                        { attrs: { label: "放假天数" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.form.holidayDays) + "天"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "法定日期" } },
                    [
                      _vm.showFlag
                        ? _c("a-range-picker", {
                            attrs: {
                              "value-format": _vm.format,
                              "default-picker-value": _vm.rangePicker,
                              "disabled-date": _vm.disabledLegalDate,
                              disabled: !_vm.form.holidayTime.length,
                            },
                            on: { change: _vm.legalTimeChange },
                            model: {
                              value: _vm.form.legalDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "legalDate", $$v)
                              },
                              expression: "form.legalDate",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "补班日期" } },
                    [
                      _vm._l(_vm.form.adjustDates, function (date, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c("a-date-picker", {
                              staticClass: "large-input",
                              attrs: {
                                "default-picker-value": _vm.moment(
                                  _vm.form.holidayTime[0],
                                  "YYYY-MM-DD"
                                ),
                                "value-format": _vm.format,
                                "disabled-date": _vm.disabledLegalDate,
                                placeholder: "节假日期",
                              },
                              model: {
                                value: date.offDayDate,
                                callback: function ($$v) {
                                  _vm.$set(date, "offDayDate", $$v)
                                },
                                expression: "date.offDayDate",
                              },
                            }),
                            _c("a-date-picker", {
                              staticClass: "large-input",
                              attrs: {
                                "value-format": _vm.format,
                                placeholder: "补班日期",
                                "disabled-date": _vm.disabledPunchDate,
                              },
                              model: {
                                value: date.workDayDate,
                                callback: function ($$v) {
                                  _vm.$set(date, "workDayDate", $$v)
                                },
                                expression: "date.workDayDate",
                              },
                            }),
                            _c("a-button", {
                              staticClass: "closeBtn",
                              attrs: { type: "iconBtn", icon: "close-small" },
                              on: {
                                click: function ($event) {
                                  return _vm.form.adjustDates.splice(index, 1)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _c("a-button", {
                        staticClass: "addBtn",
                        attrs: { type: "iconBtn", icon: "add-one" },
                        on: { click: _vm.addAdjustDates },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }